<template>
  <div class="machine-list">
    <!--    <a-alert v-if="cluster.deployEnvType === 'ALIBABA_CLOUD_HOSTED'" banner-->
    <!--             closable-->
    <!--             message="操作提示：机器添加后须在机器列表，点击【安装】按钮安装客户端。" style="margin-bottom: 10px;" type="warning"/>-->
    <a-breadcrumb style="margin-bottom: 10px;">
      <a-breadcrumb-item>
        <router-link :to="{name: 'System_Machine'}">机器管理</router-link>
      </a-breadcrumb-item>
      <a-breadcrumb-item>机器列表</a-breadcrumb-item>
    </a-breadcrumb>
    <div class="info">
      <div class="top">
        <a-input v-model="queryForm.text" class="text" placeholder="请输入机器ID/名称查询"
                 style="width: 280px;margin-right: 10px;"/>
        <a-button class="search-btn" type="primary" @click="handleQuery">查询</a-button>
      </div>
      <div class="bottom">
        <div class="left">
          <div class="circle">
            <cc-iconfont name="machine" size="16"/>
          </div>
          <div class="content">
            <div class="first">
              <div>{{ cluster.clusterDesc }}</div>
              <div>{{ cluster.clusterName }}</div>
            </div>
            <div class="second">
              <div>{{ CLUSTER_TYPE[cluster.deployEnvType] && CLUSTER_TYPE[cluster.deployEnvType].name }}</div>
              <div>{{ regionList[cluster.region] }}</div>
            </div>
          </div>
        </div>
        <div class="right">
          <a-button v-if="userInfo.authArr.includes('WORKER_ADD')" ghost icon="plus" style="margin-right: 10px;"
                    type="primary" @click="handleClickAddBtn">添加机器
          </a-button>
          <a-button class="refresh" @click="getWorkerList">刷新</a-button>
        </div>
      </div>
    </div>
    <a-table :columns="workerListColumns" :data-source="workerList" :rowKey="record => record.id" size="small">
      <template slot="name" slot-scope="record">
        {{ record.workerName }}/{{ record.workerDesc }}
        <cc-iconfont v-if="userInfo.authArr.includes('WORKER_UPDATE_DESC')" :size="8" name="edit"
                     style="margin-right: 4px;" @click.native="handleClickEditWorkerNameBtn(record)"/>
        <a-popover placement="right">
          <cc-iconfont :size="12" name="detail"/>
          <div slot="content" class="worker-detail">
            <section>
              <div class="title">公网信息:</div>
              <div>{{ record.publicIp }}</div>
            </section>
            <section>
              <div class="title">地区:</div>
              <div>不限</div>
            </section>
            <section>
              <div class="title">创建时间:</div>
              <div>{{ record.gmtCreate | formatTime('YYYY-MM-DD HH:mm:ss') }}</div>
            </section>
            <section>
              <div class="title">机器类型:</div>
              <div>虚拟机</div>
            </section>
            <section>
              <div class="title">机器唯一标识符:</div>
              <div style="margin-right: 5px;">{{ record.workerSeqNumber }}</div>
              <cc-iconfont :size="12" name="copy" @click.native="copyText(`${record.workerSeqNumber}`, '复制机器唯一标识符成功')"/>
            </section>
          </div>
        </a-popover>
        <cc-iconfont v-if="record.consoleTaskState==='FAILED'" @click.native="handleConsoleJob(record)" name="job-error"
                     color="#FF6E0D" size="16" style="margin-left: 6px"/>
      </template>
      <template slot="ip" slot-scope="record">
        {{ record.privateIp }}
      </template>
      <template slot="status" slot-scope="record">
        {{ '' }}
        <div v-if="record.deployStatus !== 'INSTALLING'&&record.deployStatus!=='UNINSTALLING'" style="display: flex">
          <cc-status :type="HEALTH_LEVEL_COLOR[record.healthLevel]"/>
          {{ WORKER_STATE[record.workerState].name }}
        </div>
        <div v-else-if="record.deployStatus === 'INSTALLING'">
          <div v-if="record.consoleTaskState === 'FAILED'" style="display:flex">
            <cc-status type="error"/>
            安装失败
          </div>
          <div v-if="record.consoleTaskState !== 'FAILED'">
            <a-icon type="loading"/>
            安装中
          </div>
        </div>
        <div v-else-if="record.deployStatus === 'UNINSTALLING'">
          <div v-if="record.consoleTaskState === 'FAILED'" style="display:flex">
            <cc-status type="error"/>
            卸载失败
          </div>
          <a-icon v-if="record.consoleTaskState !== 'FAILED'" type="loading"/>
          卸载中
        </div>
      </template>
      <template slot="actions" slot-scope="record">
        <div class="work-list-table-actions">
          <a-button v-if="userInfo.authArr.includes('WORKER_ONLINE')" :disabled="!canStart(record)" size="small"
                    type="link"
                    @click="handleAction('start', record)">启动
          </a-button>
          <a-button v-if="userInfo.authArr.includes('WORKER_OFFLINE')" :disabled="!canStop(record)" size="small"
                    type="link"
                    @click="handleAction('stop', record)">停止
          </a-button>
          <div v-if="record.deployEnvType === CLUSTER_ENV.SELF_MAINTENANCE">
            <a-button v-if="userInfo.authArr.includes('WORKER_GET_CLIENT_DOWNLOAD_LINK')" size="small" type="link"
                      @click="handleAction('download', record)">
              下载客户端
            </a-button>
            <a-button v-if="userInfo.authArr.includes('WORKER_GET_CLIENT_CORE_CONFIG')" size="small" type="link"
                      @click="handleAction('conf', record)">
              查看配置文件
            </a-button>
          </div>
          <div v-else>
            <a-button v-if="userInfo.authArr.includes('ALIYUN_ECS_INSTALL')" :disabled="!canInstall(record)"
                      size="small" type="link"
                      @click="handleAction('install', record)">
              安装
            </a-button>
            <a-button v-if="userInfo.authArr.includes('ALIYUN_ECS_UPGRADE')" :disabled="!canUpdate(record)" size="small"
                      type="link"
                      @click="handleAction('update', record)">
              更新
            </a-button>
            <a-button v-if="userInfo.authArr.includes('ALIYUN_ECS_UNINSTALL')" :disabled="!canUninstall(record)"
                      size="small"
                      type="link"
                      @click="handleAction('uninstall', record)">卸载
            </a-button>
          </div>
          <a-popconfirm v-if="userInfo.authArr.includes('WORKER_DELETE')" :disabled="!canDelete(record)"
                        cancel-text="取消" ok-text="确定"
                        title="确定删除该机器吗？" @confirm="handleAction('delete', record)">
            <a-button :disabled="!canDelete(record)" size="small" type="link">删除</a-button>
          </a-popconfirm>
        </div>
      </template>
    </a-table>
    <a-modal
      v-model="showAddWorkerModal"
      :mask-closable="false"
      :width="1240"
      title="添加机器"
    >
      <add-machine-modal v-if="showAddWorkerModal" :clusterId="parseInt(clusterId, 10)"
                         :handleAddWorker="handleAddWorker" :handleCancelAddWorker="handleCancelAddWorker"/>
    </a-modal>
    <a-modal v-model="showDownloadModal" :mask-closable="false" :width="620" title="下载客户端">
      <div class="download-modal">
        <a-alert banner message="下载链接1小时内有效，请尽快下载。" style="margin-bottom: 10px;"/>
        <div class="url">
          {{ downloadUrl }}
        </div>
        <div class="btn-group">
          <a-button type="primary" @click="handleDownload">下载</a-button>
          <a-button type="primary" @click="copyText(downloadUrl, '复制下载链接成功')">复制链接</a-button>
          <a-button @click="handleCancelDownload">取消</a-button>
        </div>
      </div>
    </a-modal>
    <a-modal v-model="showConfigModal" :mask-closable="false" :width="630" title="配置文件">
      <div v-if="showConfigModal" class="config-modal">
        <a-alert banner message="请将下列配置完整复制到CloudDM 安装目录下的 ./conf/global_conf.properties文件中"
                 style="margin-bottom: 10px;"/>
        <div class="config">
          <div>{{ workerConfig.userAkLabel }}={{ workerConfig.userAkValue }}</div>
          <div>{{ workerConfig.userSkLabel }}={{ workerConfig.userSkValue }}</div>
          <div>{{ workerConfig.wsnLabel }}={{ workerConfig.wsnValue }}</div>
          <div>{{ workerConfig.consoleDomainLabel }}={{ workerConfig.consoleDomainValue }}</div>
        </div>
        <div class="btn-group">
          <a-button type="primary" @click="copyConfig">复制</a-button>
          <a-button @click="showConfigModal=false">关闭</a-button>
        </div>
      </div>
    </a-modal>
    <a-modal v-model="showEditWorkerNameModal" :mask-closable="false" :width="400" cancelText="取消"
             okText="保存" title="修改名称" wrapClassName="have-footer" @ok="handleUpdateWorkerDesc">
      <a-input v-model="selectedWorker.workerDesc" type="textarea"/>
    </a-modal>
  </div>
</template>

<script lang="js">
import cloneDeep from 'lodash.clonedeep';
import AddMachineModal from '@views/system/cluster/AddMachineModal.vue';
// import { Modal } from 'view-design';
import { Modal } from 'ant-design-vue';
import {
  CLUSTER_ENV,
  CLUSTER_TYPE,
  DEPLOY_STATUS,
  HEALTH_LEVEL_COLOR,
  VERIFY_CODE_TYPE,
  VERIFY_TYPE,
  WORKER_STATE
} from '@/consts';
import { mapState } from 'vuex';
import copyMixin from '@/mixins/copyMixin';

export default {
  name: 'WorkerList',
  components: {
    AddMachineModal
  },
  mixins: [copyMixin],
  data() {
    return {
      CLUSTER_TYPE,
      queryForm: { text: '' },
      cluster: {},
      DEPLOY_STATUS,
      WORKER_STATE,
      HEALTH_LEVEL_COLOR,
      CLUSTER_ENV,
      countDown: 60,
      codeBtnMsg: '获取验证码',
      timer: null,
      smsCode: '',
      clusterId: '',
      downloadUrl: '',
      searchText: '',
      workerConfig: {
        consoleDomainLabel: '',
        consoleDomainValue: '',
        userAkLabel: '',
        userAkValue: '',
        userSkLabel: '',
        userSkValue: '',
        wsnLabel: '',
        wsnValue: ''
      },
      showConfModal: false,
      showAddWorkerModal: false,
      showConfigModal: false,
      showDownloadModal: false,
      workerList: [],
      workerFilterInfo: {
        query: ''
      },
      selectedWorker: {},
      showEditWorkerNameModal: false
    };
  },
  computed: {
    ...mapState({
      userInfo: (state) => state.userInfo,
      regionList: (state) => state.regionList
    }),
    workerListColumns() {
      const { query } = this.workerFilterInfo;
      return [
        {
          title: '机器ID/名称',
          filteredValue: [query] || null,
          key: 'name',
          onFilter: (value, record) => record.workerDesc.includes(value) || record.workerName.includes(value),
          scopedSlots: { customRender: 'name' }
        }, {
          title: '机器IP',
          scopedSlots: { customRender: 'ip' }
        }, {
          title: '状态',
          scopedSlots: { customRender: 'status' }
        }, {
          title: '负载',
          dataIndex: 'workerLoad',
          key: 'workerLoad'
        }, {
          title: 'CPU使用率',
          dataIndex: 'cpuUseRatio',
          key: 'cpuUseRatio'
        }, {
          title: '内存使用率',
          dataIndex: 'memUseRatio',
          key: 'memUseRatio'
        },
        {
          title: '已使用会话数',
          dataIndex: 'sessionPoolUse',
          key: 'sessionPoolUse'
        },
        {
          title: '最大会话数',
          dataIndex: 'sessionPoolMax',
          key: 'sessionPoolMax'
        },
        {
          title: '操作',
          width: 500,
          key: 'action',
          fixed: 'right',
          scopedSlots: { customRender: 'actions' }
        }];
    }
  },
  methods: {
    hideConfModal() {
      this.showConfModal = false;
    },
    async queryClusterById() {
      const res = await this.$services.getCluster({ data: { clusterId: this.clusterId } });
      if (res.success) {
        this.cluster = res.data;
      }
    },
    handleClickAddBtn() {
      this.showAddWorkerModal = true;
    },
    canStart(worker) {
      return (worker.workerState === 'OFFLINE' || worker.workerState === 'WAIT_TO_OFFLINE')
        && ((worker.deployStatus === 'INSTALLED' && worker.deployEnvType !== 'SELF_MAINTENANCE') || worker.deployEnvType === 'SELF_MAINTENANCE');
    },
    canStop(worker) {
      return worker.workerState === 'ONLINE' || worker.workerState === 'WAIT_TO_ONLINE' || worker.workerState === 'ABNORMAL';
    },
    canInstall(worker) {
      return (worker.workerState === 'OFFLINE'
        && (worker.deployStatus === 'UNINSTALLED' || worker.deployStatus === '')
        || ((worker.workerState === 'WAIT_TO_OFFLINE' && worker.deployStatus === 'UNINSTALLED')));
    },
    canUpdate(worker) {
      return worker.deployStatus === 'INSTALLED';
    },
    canUninstall(worker) {
      return (worker.workerState === 'WAIT_TO_OFFLINE' || worker.workerState === 'OFFLINE') && worker.deployStatus === 'INSTALLED';
    },
    canDelete(worker) {
      return (worker.workerState === 'OFFLINE' || worker.workerState === 'WAIT_TO_OFFLINE')
        && (worker.deployStatus === 'UNINSTALLED' && worker.deployEnvType !== 'SELF_MAINTENANCE' || worker.deployEnvType === 'SELF_MAINTENANCE')
        || (worker.deployEnvType === 'SELF_MAINTENANCE' && !worker.privateIp) || worker.healthLevel !== 'Health';
    },
    handleClickEditWorkerNameBtn(worker) {
      this.selectedWorker = cloneDeep(worker);
      this.showEditWorkerNameModal = true;
    },
    async handleUpdateWorkerDesc() {
      const {
        id,
        workerDesc
      } = this.selectedWorker;
      const data = {
        desc: workerDesc,
        workerId: id
      };
      const res = await this.$services.updateWorkerDesc({
        data,
        msg: '修改机器名称成功'
      });

      if (res.success) {
        this.getWorkerList();
        this.showEditWorkerNameModal = false;
      }
    },
    copyConfig() {
      const {
        consoleDomainLabel,
        consoleDomainValue,
        userAkLabel,
        userAkValue,
        userSkLabel,
        userSkValue,
        wsnLabel,
        wsnValue
      } = this.workerConfig;
      const value = `
      ${userAkLabel}=${userAkValue}\n
      ${userSkLabel}=${userSkValue}\n
      ${wsnLabel}=${wsnValue}\n
      ${consoleDomainLabel}=${consoleDomainValue}\n
      `;
      this.copyText(value, '配置文件复制成功');
    },
    handleQuery() {
      this.workerFilterInfo = {
        query: this.queryForm.text
      };
    },
    handleCancelDownload() {
      this.showDownloadModal = false;
    },
    async handleDownload() {
      window.open(this.downloadUrl);
    },
    async handleAddWorker(data = {}) {
      const res = await this.$services.createWorker({
        data: {
          ...data,
          clusterId: this.clusterId,
          region: 'customer'
        },
        msg: '生成机器标识符成功'
      });

      if (res.success) {
        this.showAddWorkerModal = false;
        await this.getWorkerList();
      }
    },
    async getWorkerList() {
      const data = {
        clusterId: this.clusterId
      };
      const res = await this.$services.getWorkerList({ data });
      if (res.success) {
        this.workerList = res.data;
      }
    },
    handleCancelAddWorker() {
      this.showAddWorkerModal = false;
      this.getWorkerList();
    },
    async handleAction(action, worker) {
      let res;
      const { id: workerId } = worker;
      const data = {
        clusterId: this.clusterId
      };
      if (['install', 'uninstall', 'update'].includes(action)) {
        data.workerIds = [workerId];
      } else {
        data.workerId = workerId;
      }
      switch (action) {
        case 'install':
          res = await this.$services.installAliyunEcs({
            data,
            msg: '机器安装成功'
          });
          if (res.success) {
            this.getWorkerList();
          }
          break;
        case 'uninstall':
          this.workerList.forEach((w) => {
            if (w.id === workerId) {
              w.deployStatus = 'UNINSTALLING';
            }
          });
          this.workerList = [...this.workerList];
          res = await this.$services.uninstallAliyunEcs({
            data,
            msg: '机器卸载成功'
          });
          // if (res.success) {
          //   this.getWorkerList();
          // }
          break;
        case 'update':
          res = await this.$services.upgradeAliyunEcs({
            data,
            msg: '机器更新成功'
          });
          if (res.success) {
            this.getWorkerList();
          }
          break;
        case 'start':
          res = await this.$services.startWorker({
            data,
            msg: '机器启动成功'
          });
          if (res.success) {
            this.getWorkerList();
          }
          break;
        case 'stop':
          res = await this.$services.stopWorker({
            data,
            msg: '机器停止成功'
          });

          if (res.success) {
            this.getWorkerList();
          }
          break;
        case 'download':
          res = await this.$services.getDownloadUrl({ data });
          if (res.success) {
            this.downloadUrl = res.data;
            this.showDownloadModal = true;
          }
          break;
        case 'delete':
          res = await this.$services.deleteWorker({ data });
          if (res.success) {
            this.getWorkerList();
          }
          break;
        case 'conf':
          Modal.confirm({
            title: '配置文件验证',
            width: 370,
            okText: '确定',
            cancelText: '取消',
            onOk: () => this.getWorkerConfig(data),
            onCancel: () => this.hideSmsModal(),
            content: () => (
              <div class="sms-modal">
                <div>获取配置文件需短信验证</div>
                <div class="code">
                  <a-input style="margin-right:6px" v-model={this.smsCode}/>
                  <cc-sms-button login verifyType={VERIFY_TYPE.SMS}
                                 verifyCodeType={VERIFY_CODE_TYPE.FETCH_WORKER_DEPLOY_CORE_CONFIG}/>
                </div>
              </div>
            )
          });
          break;
        default:
          return false;
      }
    },
    hideSmsModal() {
      Modal.destroyAll();
      this.smsCode = '';
    },
    async getWorkerConfig(data = {}) {
      data.verifyCode = this.smsCode;
      const res = await this.$services.getWorkerConfig({ data });
      if (res.success) {
        Modal.destroyAll();
        this.workerConfig = res.data;
        this.showConfigModal = true;
        this.smsCode = '';
      }
    },
    handleConsoleJob(record) {
      this.$router.push({ path: `/console_job/${record.consoleJobId}` });
    }
  },
  created() {
    this.clusterId = this.$route.params.clusterId;
    this.queryClusterById();
    this.getWorkerList();
  }
};
</script>

<style lang="less" scoped>
.machine-list {

  .info {
    .bottom {
      margin-top: 10px;
      height: 70px;
      width: 100%;
      background-color: rgba(250, 250, 250, 1);
      border: 1px solid rgba(234, 234, 234, 1);
      border-bottom: none;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-right: 20px;

      .left {
        display: flex;

        .circle {
          width: 40px;
          height: 40px;
          border-radius: 50%;
          background-color: rgba(255, 193, 92, 1);
          box-shadow: 0px 3px 12px 0px rgba(255, 192, 92, 1);
          text-align: center;
          line-height: 40px;
          margin-left: 20px;
        }

        .content {
          display: flex;
          flex-direction: column;
          margin-left: 17px;

          & > div {
            display: flex;
          }

          .first {
            display: flex;
            align-items: center;
            margin-bottom: 6px;

            & > div:first-child {
              font-size: 14px;
              font-weight: bold;
              margin-right: 10px;
            }
          }

          .second {
            & > div {
              border-radius: 14px;
              padding: 0 5px;
              background-color: rgba(224, 224, 224, 1);

              &:first-child {
                margin-right: 8px;
              }
            }
          }
        }
      }
    }
  }

  .header {
    margin-top: 20px;
    margin-bottom: 12px;
    display: flex;
    justify-content: space-between;

    .operation {
    }
  }

}

.ivu-poptip-body-content {
  .worker-detail {
    display: flex;
    flex-wrap: wrap;
    width: 600px;
    padding: 10px 0;

    section {
      margin-left: 10px;
      width: 250px;
      display: flex;
      margin-bottom: 5px;

      .title {
        font-weight: bold;
      }
    }
  }
}

.download-modal {
  .url {
    padding: 10px;
    border: 1px solid #EDEDED;
    background: #FAFAFA;
    word-wrap: break-word;
    margin-bottom: 20px;
  }

  .btn-group {
    display: flex;
    justify-content: center;

    button + button {
      margin-left: 10px;
    }
  }
}

.sms-modal {
  .code {
    display: flex;
    margin-top: 10px;
  }

  .footer {
    margin-top: 20px;

    button {
      width: 120px;

      &:first-child {
        margin-right: 16px;
      }
    }
  }
}

.config-modal {
  .config {
    border: 1px solid #EDEDED;
    background: #FAFAFA;
    padding: 10px;
  }

  .btn-group {
    display: flex;
    justify-content: center;
    margin-top: 20px;

    button + button {
      margin-left: 10px;
    }
  }
}

.work-list-table-actions {
  display: flex;
}

.worker-detail {
  display: flex;
  flex-wrap: wrap;
  width: 600px;

  section {
    display: flex;
    height: 20px;
    line-height: 20px;

    .title {
      color: #888;
    }

    div:last-child {
      font-weight: bold;
    }

    &:not(:last-child) {
      width: 50%;
    }
  }
}
</style>
