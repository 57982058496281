const copyMixin = {
  methods: {
    copyText(value, msg = '复制成功') {
      const textArea = document.createElement('textarea');
      textArea.value = value;
      document.body.appendChild(textArea);
      textArea.select();
      if (document.execCommand('copy')) {
        document.execCommand('copy');
        document.body.removeChild(textArea);
        if (msg) {
          this.$Message.success(msg);
        }
      }
    }
  }
};

export default copyMixin;
