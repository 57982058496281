<template>
  <div class="add-machine">
    <div class="header">
      <div class="cycle">
        <cc-iconfont :size=12 color="white" name="machine"/>
      </div>
      <div class="choose">
        <div class="title">管控数据库/service_product_0001</div>
        <div class="content">
          <cc-label required style="margin-right: 10px;">云或机房名称</cc-label>
          <div v-if="isDefaultCluster">
            <cc-cluster-type-select v-model="cluster.deployEnvType" :deployEnvList="Object.values(deployEnvListMap)"/>
          </div>
          <div v-else>
            <a-button :disabled="!isSelfCluster" style="width: 120px;" :type="isSelfCluster ? 'primary' : 'default'">自建数据库</a-button>
            <a-button style="width: 120px;" :disabled="isSelfCluster" :type="!isSelfCluster ? 'primary' : 'default'">阿里云</a-button>
            <!--            {{ CLUSTER_TYPE[cluster.deployEnvType] && CLUSTER_TYPE[cluster.deployEnvType].name }}-->
          </div>
          <div style="display: flex;">
            <cc-label required style="margin-left: 60px;margin-right: 10px;">地区</cc-label>
            <cc-region-select v-if="isDefaultCluster" v-model="cluster.region" :env="cluster.deployEnvType"/>
            <div v-else>
              <a-button disabled style="width: 180px;text-align: left;color: #bbb!important;" ghost>{{ regionList[cluster.region] }}</a-button>
            </div>
            <a-button v-if="cluster.deployEnvType==='ALIBABA_CLOUD_HOSTED'" ghost
                      style="width:116px;margin-left:10px"
                      type="primary" @click="getEcsList()">获取ECS实例
            </a-button>
          </div>
        </div>
      </div>
    </div>
    <div v-if="cluster.deployEnvType===CLUSTER_ENV.ALIBABA_CLOUD_HOSTED" class="body">
      <div class="un-select">
        <div class="query">
          <div class="title">待选择机器</div>
          <div class="condition">
            <a-input v-model="query" placeholder="请输入描述名称" style="width: 200px;margin-right: 10px;"/>
            <a-button type="primary" @click="handleQuery">查询</a-button>
          </div>
        </div>
        <div class="table">
          <a-table :columns="unSelectedWorkerColumns" :data-source="unSelectedWorkers"
                   :row-selection="rowSelection"
                   :rowKey="record=>record.instanceId"
                   border size="small" style="flex:1"
          >
            <template slot="name" slot-scope="record">
              {{ record.instanceId }}/{{ record.instanceName }}
              <a-popover>
                <cc-iconfont v-if="!record.supported" color="#FF6E0D" name="warning-ghost"/>
                <div slot="content">
                  <div v-for="reason in record.unSupportedReasons" :key="reason">{{ reason }}</div>
                </div>
              </a-popover>
            </template>
            <template slot="ip" slot-scope="record">
              {{ record.allPublicIp[0] }}
            </template>
            <template slot="expiredTime" slot-scope="record">
              {{ record.expiredTime | formatTime('YYYY/MM/DD') }}
            </template>
          </a-table>
        </div>
      </div>
      <div class="selected">
        <div class="query">
          <div class="title">已选择机器</div>
        </div>
        <div class="table">
          <a-table :columns="selectedWorkerColumns" :data-source="selectedWorkers" :rowKey="record=>record.instanceId"
                   :width="340" size="small">
            <template slot="name" slot-scope="record">
              {{ record.instanceId }}/{{ record.instanceName }}
            </template>
            <template slot="actions" slot-scope="record">
              <a-button type="link" @click="handleDeleteMachine(record.instanceId)">删除</a-button>
            </template>
          </a-table>
        </div>
      </div>
    </div>
    <div v-else class="body self">
      <div class="title">
        <span>自建数据库添加机器的步骤</span>(目前仅支持Redhat , CentOS ,Ubuntu, MacOS系统)
      </div>
      <div class="content">
        <section>
          <div class="num">
            1
          </div>
          <div class="info">
            <cc-iconfont :size="120" name="bar-code"/>
          </div>
          <a-button type="primary" @click="_handleAddMachine">生成机器唯一标识</a-button>
        </section>
        <section>
          <div class="num">
            2
          </div>
          <img alt="" class="info" src="../../../assets/example.png">
          将下载的客户端压缩包到迁移机器并解压至非用户主目录。
        </section>
        <section>
          <div class="num">
            3
          </div>
          <div class="info">
            <div>将获取的核心配置文件复制到：</div>
            <div>～/clouddm/global_conf/conf.properties 中替换。</div>
            <div>使用非root用户启动客户端</div>
            <div>客户端解压目录/clouddm/sidecar/bin/startSidecar.sh</div>
          </div>
        </section>
      </div>
    </div>
    <div class="footer">
      <a-button v-if="cluster.deployEnvType === CLUSTER_ENV.ALIBABA_CLOUD_HOSTED"
                style="width:120px;margin-right: 16px;" type="primary"
                @click="handleAddEcs">添加
      </a-button>
      <a-button style="width:120px;" @click.native="handleCancelAddWorker">取消</a-button>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { ACTIONS_TYPE } from '@/store/actions';
import { CLUSTER_ENV, CLUSTER_TYPE } from '@/consts';
import { Modal } from 'ant-design-vue';

export default {
  name: 'AddMachineModal',
  props: {
    handleCancelAddWorker: Function,
    handleAddWorker: Function,
    clusterId: Number
  },
  computed: {
    isSelfCluster() {
      return this.cluster.deployEnvType === CLUSTER_ENV.SELF_MAINTENANCE;
    },
    ...mapState({
      deployEnvListMap: (state) => state.deployEnvListMap,
      regionList: (state) => state.regionList
    }),
    rowSelection() {
      return {
        selectedRowKeys: this.selectedWorkerKeys,
        onChange: (selectedRowKeys, selectedRows) => {
          this.selectedWorkers = selectedRows;
          this.selectedWorkerKeys = selectedRowKeys;
        },
        getCheckboxProps: (record) => ({
          props: {
            disabled: !record.supported
          }
        })
      };
    },
    unSelectedWorkerColumns() {
      const { query } = this.unSelectedWorkerFilter;
      const columns = [
        {
          title: '实例ID/名称',
          filteredValue: [query] || null,
          key: 'name',
          onFilter: (value, record) => record.instanceId.includes(value) || record.instanceName.includes(value),
          scopedSlots: { customRender: 'name' }
        },
        {
          title: 'IP',
          scopedSlots: { customRender: 'ip' }
        },
        {
          title: '操作系统',
          key: 'osname',
          dataIndex: 'osname'
        }, {
          title: 'CPU',
          key: 'cpuCount',
          dataIndex: 'cpuCount'
        }, {
          title: '内存',
          key: 'memoryMb',
          dataIndex: 'memoryMb'
        }, {
          title: '过期时间',
          scopedSlots: { customRender: 'expiredTime' }
        }
      ];
      return columns;
    }
  },
  data() {
    return {
      query: '',
      CLUSTER_TYPE,
      CLUSTER_ENV,
      isDefaultCluster: false,
      cluster: {
        deployEnvType: CLUSTER_ENV.ALIBABA_CLOUD_HOSTED,
        region: ''
      },
      unSelectedWorkers: [],
      unSelectedWorkerFilter: {
        query: ''
      },
      selectedWorkerColumns: [
        {
          title: '实例ID/名称',
          scopedSlots: { customRender: 'name' }
        }, {
          title: '操作',
          scopedSlots: { customRender: 'actions' }
        }
      ],
      selectedWorkers: [],
      selectedWorkerKeys: [],
      ecsList: []
    };
  },
  methods: {
    handleQuery() {
      this.unSelectedWorkerFilter = { query: this.query };
    },
    async getCluster() {
      const res = await this.$services.getCluster({ data: { clusterId: this.clusterId } });
      if (res.success) {
        this.cluster = res.data;
        if (this.cluster.deployEnvType === null) {
          this.isDefaultCluster = true;
        }
      }
    },
    async getEcsList() {
      if (!this.cluster.region) {
        Modal.error({
          content: '请选择区域'
        });
        return;
      }
      const res = await this.$services.getEcsList({
        data: {
          clusterId: this.clusterId,
          region: this.cluster.region
        }
      });

      if (res.success) {
        // console.log(res.data);
        this.unSelectedWorkers = res.data;
      }
    },
    async _handleAddMachine() {
      const {
        region,
        deployEnvType
      } = this.cluster;
      if (region && deployEnvType) {
        const data = {
          region,
          deployEnvType
        };
        await this.handleAddWorker(data);
      } else {
        this.$Message.error('请选择区域');
      }
    },
    handleDeleteMachine(instanceKey) {
      this.selectedWorkerKeys = this.selectedWorkerKeys.filter((key) => key !== instanceKey);
      this.selectedWorkers = this.selectedWorkers.filter((worker) => worker.instanceId !== instanceKey);
    },
    async handleAddEcs() {
      const data = {
        clusterId: this.clusterId,
        ecsInstanceIds: this.selectedWorkerKeys,
        region: this.cluster.region
      };

      const res = await this.$services.addEcs({
        data,
        msg: '添加机器成功'
      });
      if (res.success) {
        this.handleCancelAddWorker();
      }
    }
  },
  created() {
    this.$store.dispatch(ACTIONS_TYPE.GET_DEPLOY_ENV_LIST);
    this.getCluster();
  }
};
</script>

<style lang="less" scoped>
.add-machine {
  .header {
    background: @gray;
    height: 92px;
    display: flex;
    padding: 10px 20px;
    border: 1px solid #DADADA;

    .cycle {
      .circle(24px, #FFC15C);
      .flex-center();
      box-shadow: 0 3px 12px #FFC05C;
    }

    .choose {
      margin-left: 15px;

      .title {
        color: @font-color !important;
        line-height: 24px;
        font-weight: bold;
      }

      .content {
        margin-top: 10px;
        .flex-center();
      }
    }
  }

  .body {

    margin-top: 20px;
    //min-height: 490px;
    //max-height: 490px;
    display: flex;
    justify-content: space-between;

    .title {
      font-weight: bold;
      line-height: 32px;
    }

    .table {
      flex: 1;
      display: flex;
      flex-direction: column;
      margin-top: 6px;

      .pagination {
        .flex-center();
        height: 48px;
        border: 1px solid #EAEAEA;
        border-top: none;
      }
    }

    .un-select {
      width: 850px;
      margin-right: 10px;
      display: flex;
      flex-direction: column;

      .query {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .condition {
          display: flex;
          align-items: center;
        }
      }
    }

    .selected {
      flex: 1;
      display: flex;
      flex-direction: column;
      height: 490px;

      .table {
        min-width: 340px;

        .pagination {
          border: none;
        }
      }
    }
  }

  .self {
    height: 392px;
    border: 1px solid #ececec;
    margin-top: 0;
    display: flex;
    flex-direction: column;

    .title {
      margin-left: 54px;
      margin-top: 12px;
    }

    .content {
      margin-top: 23px;
      flex: 1;
      display: flex;
      justify-content: space-around;

      section {
        display: flex;
        flex-direction: column;
        align-items: center;

        .info {
          width: 277px;
          height: 183px;
          background: #DEEFFF;
          margin-bottom: 16px;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        &:first-child {
          .info {
            width: 200px;
          }
        }

        &:last-child {
          .info {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 320px;

            div:nth-child(odd) {
              font-weight: bold;
              margin-bottom: 10px;
              margin-top: 14px;
            }
          }
        }

        .num {
          width: 42px;
          height: 42px;
          border-radius: 50%;
          background: #DEEFFF;
          font-size: 24px;
          line-height: 42px;
          text-align: center;
          margin-bottom: 26px;
        }
      }
    }
  }

  .footer {
    text-align: center;
  }
}
</style>
